import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ErrorPage = () => {
  return (
    <div
      style={{ height: "60vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="alert alert-danger text-center d-flex flex-column gap-5"
        role="alert"
      >
        <h3>Your Payment has been cancelled Try Again</h3>
        <Link to="/pricing">
          <Button variant="danger">Continue</Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
