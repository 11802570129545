import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const SuccessPage = () => {
  return (
    <div
      style={{ height: "60vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="alert alert-primary text-center d-flex flex-column gap-5"
        role="alert"
      >
        <h3> Congratulations your payment successful</h3>
        <Link to="/">
          <Button variant="primary">Continue To Conversation</Button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
