import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinners from "./Spinners";

const Pricing = () => {
  const [stripeUser, setStripeUser] = useState("");
  const [stripeProduct, setStripeProduct] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  //my times

  //my times
  const {
    user: { token, email, isPaid, customerId },
  } = useSelector((state) => state.auth);

  const onPaymentHandler = async () => {
    //strpeProduct is a priceid of a product
    const sendData = {
      customerId,
      productId: stripeProduct.id,
    };

    try {
      setLoader(true);
      const { data } = await axios.post(
        process.env.REACT_APP_SERVER_URL + `/stripe/payment`,
        sendData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setLoader(false);

      window.location.replace(data.url);
    } catch (err) {
      setLoader(false);
      setError(err.response.data.message);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const { data: res } = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/stripe/price`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setStripeProduct(res.data[0]);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      setError(err.response.data.message);
    }
  };

  //fetch for filter user in stripe

  const fetchFilterStripeUser = async () => {
    setLoader(true);
    try {
      const { data: res } = await axios.get(
        process.env.REACT_APP_SERVER_URL + `/stripe/filteruser`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setStripeUser(res);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFilterStripeUser();
  }, []);

  const startTimestamp = stripeUser?.current_period_start;
  const endTimestamp = stripeUser?.current_period_end;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString();

    return `${day} ${month} ${year} ${time}`;
  };

  const startDateFormatted = formatTimestamp(startTimestamp);
  const endDateFormatted = formatTimestamp(endTimestamp);

  //start remaning time

  const [remainingTime, setRemainingTime] = useState("");

  const calculateRemainingTime = (
    endTimestamp = stripeUser?.current_period_end
  ) => {
    const now = new Date().getTime() / 1000; // Current timestamp in seconds
    const remainingSeconds = endTimestamp - now;

    if (remainingSeconds <= 0) {
      return "Expired";
    }

    const remainingDays = Math.floor(remainingSeconds / (3600 * 24));
    const remainingHours = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
    const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
    const remainingSecondsOnly = Math.floor(remainingSeconds % 60);

    return `${remainingDays} days, ${remainingHours} hours, ${remainingMinutes} minutes, ${remainingSecondsOnly} seconds`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = calculateRemainingTime(endTimestamp);
      setRemainingTime(newRemainingTime);
    }, 1000); // Update every 1 second

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [endTimestamp]);

  return (
    <div>
      {loader ? (
        <div className="d-flex justify-content-center loaderss">
          <Spinners />
        </div>
      ) : (
        <>
          {stripeUser?.status === "active" ? (
            <div className="timer">
              <div className="container mt-5">
                <h2>
                  <span className="fw-bolder me-2">Payment Status</span> :
                  <span className="ms-2">
                    <Button variant="danger px-5 py-2">
                      {stripeUser.status}
                    </Button>
                  </span>
                </h2>
                <h3>
                  <span className="fw-bolder">Start Date</span> :
                  <span className="ms-2">{startDateFormatted}</span>
                </h3>
                <h3>
                  <span className="fw-bolder">End Date</span> :
                  <span className="ms-2">{endDateFormatted}</span>
                </h3>
                <div className="main-timer d-flex justify-content-center align-items-center">
                  <span className="fw-bolder me-2" style={{ fontSize: "25px" }}>
                    Remaining Time :{" "}
                  </span>{" "}
                  <span className="ms-2">
                    <Button variant="success px-4 py-3">{remainingTime}</Button>{" "}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="prising d-flex justify-content-center container">
                <div className="p-body d-flex flex-column gap-3">
                  <div className="p-text">
                    <h1>Start using Dr. Mega for free!</h1>
                    <p>
                      Switch to <span className="fw-bolder">Dr. Mega Plus</span>{" "}
                      to use unlimited messages and more advanced features.
                    </p>
                  </div>
                  <div className="p-card d-flex gap-3 mt-4 ">
                    <div className="l-card container">
                      <div className="mt-3">
                        <h3 className="fw-bolder">Free</h3>
                        <p>Use Dr. Gupta for free</p>
                        <h1>
                          <span className="fw-bolder display-3">$0</span>/mo
                        </h1>
                        <ul className="list-group">
                          <li className="list-group-item">
                            12 messages a month
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="r-card container">
                      <div className="mt-3">
                        <h4 className="fw-bolder">Plus</h4>
                        <p>Full access</p>
                        <h1>
                          <span className="fw-bolder display-3">$20</span>/mo
                        </h1>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <div className="fw-bolder">Unlimited</div> messages
                            a month
                          </li>
                          <li className="list-group-item">
                            Early access to new features
                          </li>
                          <li className="list-group-item">
                            Ad-free experience
                          </li>
                        </ul>
                      </div>
                      <div className="text-center  mt-4">
                        {token ? (
                          <>
                            {isPaid ? (
                              <>
                                <Button disabled size="lg" variant="primary">
                                  Subscription Active
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={onPaymentHandler}
                                size="lg"
                                variant="primary"
                              >
                                {loader ? <Spinners /> : "Get Plus"}
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            <Link to="/login">
                              <Button size="lg" variant="primary">
                                Sign In To Get Plus
                              </Button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {error && token && <div className="text-danger">{error}</div>}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Pricing;
